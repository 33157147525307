/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import SectionHeader from 'components/section-header';
import AssetCard from 'components/cards/asset-card';

const AssetClasses = () => {
  const data = useStaticQuery(graphql`
    query {
      RealEstate: file(relativePath: { eq: "asset-classes/real-estate.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      Contents: file(relativePath: { eq: "asset-classes/contents.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      Artworks: file(relativePath: { eq: "asset-classes/artworks.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      Collectables: file(relativePath: { eq: "asset-classes/collectables.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      Stock: file(relativePath: { eq: "asset-classes/stock.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      FixedIncome: file(relativePath: { eq: "asset-classes/bond.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      IntellectualProperty: file(relativePath: { eq: "asset-classes/ip.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      GreenEconomy: file(relativePath: { eq: "asset-classes/green-economy.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
    }
  `);

  const ASSET_CLASSES = [
    {
      title: 'Real Estate',
      image: data.RealEstate.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Contents',
      image: data.Contents.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Artworks',
      image: data.Artworks.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Collectables',
      image: data.Collectables.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Stock',
      image: data.Stock.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Fixed Income',
      image: data.FixedIncome.childImageSharp.gatsbyImageData,
    },
    {
      title: 'IP',
      image: data.IntellectualProperty.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Green Economy',
      image: data.GreenEconomy.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <Box as="section" id="asset-classes" sx={styles.section}>
      <Container>
        <SectionHeader
          title="Tokenizable Asset Classes"
          slogan={"Simplify your processes and stay ahead in the rapidly evolving world of digital finance \nwith our all-in-one management system for security tokens."}
        />
        <Box sx={styles.grid}>
          {ASSET_CLASSES.map((assets, i) => (
            <AssetCard key={i} assets={assets} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default AssetClasses;

const styles = {
  section: {
    pt: '8',
    pb: '8',
  },
  grid: {
    gap: [3, null, null, 4],
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: [
      'repeat(2, 1fr)',
      null,
      null,
      null,
      'repeat(4, 1fr)',
      null,
      'repeat(4, 300px)',
    ],
  },
};