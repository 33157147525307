/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const AssetCard = ({ assets }) => {
  // const image = getImage(assets.image);
  return (
    <Box sx={styles.assets}>
      <GatsbyImage image={assets.image} alt={assets.title} sx={styles.image} />
      <Text as="p" sx={styles.text}>{assets.title}</Text>
    </Box>
  );
};

export default AssetCard;

const styles = {
  assets: {
    position: 'relative', 
    height: '100px',
    border: `1px solid #edeff6`,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: [107, null, null, 250],
    textAlign: 'center',
    transition: 'all 0.3s ease-in-out 0s',
    ':hover': {
      boxShadow: '0px 15px 30px rgba(51, 83, 145, 0.2)',
      borderColor: 'transparent',
      transform: 'scale(1.01)',
      filter: 'brightness(1.1)',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
  },
  text: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    fontWeight: 1000,
    fontSize: ['17px', '20px', null, '25px'],
    color: 'white',
    textShadow: '3px 3px 5px rgba(0, 0, 0, 1)',
    padding: '5px',
    borderRadius: '3px',
    whiteSpace: 'nowrap',
  },
};