import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import Seo from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import Alliance from 'sections/alliance';
import Mission from 'sections/mission';
import Services from 'sections/services';
import Portfolio from 'sections/portfolio';
import AssetClasses from 'sections/asset-classes';
import Team from 'sections/team';
import Investors from 'sections/investors';
import MoreInfo from 'sections/more-info';
import Contact from 'sections/contact';

import { motion } from 'framer-motion';

import 'rc-drawer/assets/index.css';
import 'react-modal-video/css/modal-video.min.css';


const AnimatedSection = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 70 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <Seo
          title="SPLIT"
          description="RWA Tokenization and Exchange Platform based-on Blockchain Technologies."
        />
        <Banner />
        <AnimatedSection><Alliance /></AnimatedSection>
        <AnimatedSection><Mission /></AnimatedSection>
        <Services />
        <AnimatedSection><Portfolio /></AnimatedSection>
        <AnimatedSection><AssetClasses /></AnimatedSection>
        <AnimatedSection><Team /></AnimatedSection>
        <AnimatedSection><Investors /></AnimatedSection>
        <AnimatedSection><MoreInfo /></AnimatedSection>
        <AnimatedSection><Contact /></AnimatedSection>
      </Layout>
    </StickyProvider>
  );
}