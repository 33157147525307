import React from 'react';
import { Container, Box, Flex, Heading, Image as Img } from 'theme-ui';

import { investors_ci } from 'assets/assets.js';


const INVESTORS = {
  investors_blockTitle: {
    title: 'Our Investors',
    text: 'FUNBLE has secured investments from various firms and companies',
  },
  investors_posts: [
    {
      icon: investors_ci.sga_sol,
      title: 'SGA Solutions',
    },
    {
      icon: investors_ci.sk_securities,
      title: 'SK Securities',
    },
    {
      icon: investors_ci.hanwha_hotels_resorts,
      title: 'Hanwha Hotels & Resorts',
    },
    {
      icon: investors_ci.maple_investment_partners,
      title: 'MAPLE Investment Partners',
    },
    {
      icon: investors_ci.woori_tech_investment,
      title: 'WOORI Technology Investment',
    },
    {
      icon: investors_ci.the_square,
      title: 'THE SQUARE & COMPANY',
    },
  ],
};

const Investors = () => {
  const { investors_blockTitle, investors_posts } = INVESTORS;
  return (
    <Box as="section" id="partners" sx={styles.section}>
      <Container sx={styles.container}>
        <Box sx={styles.blockTitle}>
          <Heading as="h2">{investors_blockTitle.title}</Heading>
        </Box>
        <Flex sx={styles.row}>
          {investors_posts.map(({ icon, title }, index) => (
            <Box key={`investors-post-key-${index}`} sx={styles.investors_post}>
              <Box sx={styles.imageWrap}>
                <Img src={icon} alt="icon image" />
              </Box>
            </Box>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default Investors;

const styles = {
  section: {
    pt: '8',
    pb: '8',
  },
  container: {
    position: 'relative',
  },
  blockTitle: {
    textAlign: 'center',
    mb: ['35px', null, null, '55px', null, '60px', '85px', '95px', '110px'],
    h2: {
      fontSize: ['28px', '32px', null, '25px', '30px', '35px', '40px', '50px'],
      lineHeight: [1.4, null, null, 1.35],
      fontFamily: 'archivo',
      letterSpacing: '-1.5px',
      fontWeight: 'bold',
      mb: ['0', '20px', '30px', '40px', '50px'],
      color: '#000000',
    },
    p: {
      fontSize: ['15px', null, '16px'],
      lineHeight: 1.85,
      color: 'text_secondary',
    },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0,
  },
  investors_post: {
    mb: ['32px', null, null, null, 7],
    flex: ['0 0 50%', null, '0 0 50%', null, '0 0 33.3%'],
    textAlign: 'center',
    h3: {
      fontSize: ['18px', null, null, null, null, '20px'],
      lineHeight: 1.45,
      letterSpacing: '-0.5px',
      fontWeight: '500',
      color: '#02073E',
      mt: ['18px', '20px', null, null, '25px', '30px', null, '40px'],
      mb: ['10px', '15px', null, null, null, '20px'],
    },
    p: {
      maxWidth: '266px',
      mx: 'auto',
      color: '#02073E',
      fontSize: ['14px', '15px'],
      lineHeight: 2,
      px: [null, null, null, null, '5px', 0],
    },
  },
  alliance_post: {
    mb: ['32px', null, null, null, 0],
    flex: ['0 0 50%', null, '0 0 50%', null, '0 0 25%'],
    textAlign: 'center',
    h3: {
      fontSize: ['18px', null, null, null, null, '20px'],
      lineHeight: 1.45,
      letterSpacing: '-0.5px',
      fontWeight: '500',
      color: '#02073E',
      mt: ['18px', '20px', null, null, '25px', '30px', null, '40px'],
      mb: ['10px', '15px', null, null, null, '20px'],
    },
    p: {
      maxWidth: '266px',
      mx: 'auto',
      color: '#02073E',
      fontSize: ['14px', '15px'],
      lineHeight: 2,
      px: [null, null, null, null, '5px', 0],
    },
  },
  imageWrap: {
    display: 'flex',
    minHeight: ['auto', '83px'],
    alignItems: 'center',
    justifyContent: 'center',
    img: {
      width: ['150px', null, '180px', null, '200px'],
      filter: 'grayscale(100%)',
    },
  },
};
