/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import SectionHeader from 'components/section-header';
import PortfolioCard from 'components/cards/portfolio-card';


const Portfolio = () => {
  const portfolioImage = useStaticQuery(graphql`
    query {
      Portfolio1: file(relativePath: { eq: "portfolio/portfolio1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      Portfolio2: file(relativePath: { eq: "portfolio/portfolio2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
    }
  `);

  const PORTFOLIO = [
    {
      id: 1,
      title: 'Lotte Signiel',
      location: 'in Seoul, South Korea',
      image: portfolioImage.Portfolio1.childImageSharp.gatsbyImageData,
      features: [
        {
          contentTitle: 'Sold at U$5.1 Mil. (€4.8 Mil.)',
          description: 'Exited within 8-month period.'
        },
        {
          contentTitle: 'IRR 10.6%',
          description: 'Operating Dividends + Sales Profit Annualized cumulative yield based on offering price at subscription.'
        },
        {
          contentTitle: '1st Ever Sale of Underlying Asset of Security Token',
          description: 'Decision to sell made via electronic voting based on blockchain. 92% of all eligible to vote participated in voting process; 98% supported the sale.'
        }
      ]
    },
    {
      id: 2,
      title: 'Haeundae LCT',
      location: 'in Busan, South Korea',
      image: portfolioImage.Portfolio2.childImageSharp.gatsbyImageData,
      features: [
        {
          contentTitle: 'U$2.1 Mil. (€2.0 Mil.)',
          description: 'Early Close.'
        },
        {
          contentTitle: 'Busan Landmark',
          description: 'Beachfront location, stunning ocean view.'
        },
        {
          contentTitle: '11.13% Increase',
          description: 'Most recent 2-year average increase in official land value.'
        },
        {
          contentTitle: 'Stable Rent (Regular Dividends)',
          description: 'Lock-up of 38-month lease with high-credit single tenant.'
        }
      ]
    }
  ];


  return (
    <Box as="section" id="portfolio" sx={styles.section}>
      <Container>
        <SectionHeader
          slogan={"Discover a diverse selection of tokenized world-class assets. See why adding Digital Securities \nto your portfolio is essential for achieving balanced and robust financial growth."}
          title="Explore Our World-Class Tokenized Assets"
        />
        <Box sx={styles.grid}>
          {PORTFOLIO.map((portfolios, i) => (
            <PortfolioCard key={i} portfolios={portfolios} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Portfolio;

const styles = {
  section: {
    pt: '8',
    pb: '8',
    backgroundColor: '#f4f4f7',
  },
  grid: {
    gap: [3, null, null, 4],
    display: 'grid',
    justifyContent: 'center',
    // gridTemplateColumns: [
    //   'repeat(1, 1fr)',
    //   null,
    //   null,
    //   'repeat(2, 1fr)',
    //   null,
    //   null,
    //   'repeat(2, 500px)',
    // ],
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(1, 400px)',
      'repeat(1, 500px)',
      'repeat(2, 360px)',
      'repeat(2, 450px)',
      null,
      'repeat(2, 500px)',
    ],
  },
};
