import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Container, Flex, Heading, Text } from 'theme-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const TOKEN_ISSUANCE_DATA = {
  title: 'Token Issuance:',
  text:
    'Experience seamless token issuance with SPLIT. Mint tokens effortlessly with just one click. Unlock new possibilities in the digital asset space and elevate your digital asset strategy.',
};

const TOKEN_EXCHANGE_DATA = {
  title: 'Token Exchange:',
  text:
    'Gain direct connectivity to secondary token markets with our platform. Effortlessly trade and manage your digital assets in a secure, efficient, and transparent environment, unlocking new opportunities for growth and liquidity.',
};

const TOKEN_MANAGEMENT_DATA = {
  title: 'Token Management:',
  text:
    'From initial issuance and distribution to secondary trading and regulatory compliance, our platform provides a seamless and integrated solution for managing every aspect of your tokens.',
};

const AnimatedSection = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 80 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      serviceIssuanceImage: file(relativePath: { eq: "service_issuance.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      serviceExchangeImage: file(relativePath: { eq: "service_exchange.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
      serviceManagementImage: file(relativePath: { eq: "service_management.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
    }
  `);

  const serviceIssuanceImage = getImage(data.serviceIssuanceImage.childImageSharp);
  const serviceExchangeImage = getImage(data.serviceExchangeImage.childImageSharp);
  const serviceManagementImage = getImage(data.serviceManagementImage.childImageSharp);

  return (
    <Box as="section" id="services">
      <Container sx={{pt: '100px'}}>
        <AnimatedSection>
          <Flex sx={styles.row_left}>
            <Box sx={styles.content_left}>
              <Heading as="h2">{TOKEN_ISSUANCE_DATA.title}</Heading>
              <Text as="p">{TOKEN_ISSUANCE_DATA.text}</Text>
            </Box>
            <Box sx={styles.thumbWrap}>
              <Box sx={styles.thumbnail}>
                <GatsbyImage
                  image={serviceIssuanceImage}
                  alt="Token Issuance"
                />
              </Box>
            </Box>
          </Flex>
        </AnimatedSection>

        <AnimatedSection>
          <Flex sx={styles.row_right}>
            <Box sx={styles.thumbWrap}>
              <Box sx={styles.thumbnail}>
                <GatsbyImage
                  image={serviceExchangeImage}
                  alt="Token Exchange"
                />
              </Box>
            </Box>
            <Box sx={styles.content_right}>
              <Heading as="h2">{TOKEN_EXCHANGE_DATA.title}</Heading>
              <Text as="p">{TOKEN_EXCHANGE_DATA.text}</Text>
            </Box>
          </Flex>
        </AnimatedSection>

        <AnimatedSection>
          <Flex sx={styles.row_left}>
            <Box sx={styles.content_left}>
              <Heading as="h2">{TOKEN_MANAGEMENT_DATA.title}</Heading>
              <Text as="p">{TOKEN_MANAGEMENT_DATA.text}</Text>
            </Box>
            <Box sx={styles.thumbWrap}>
              <Box sx={styles.thumbnail}>
                <GatsbyImage
                  image={serviceManagementImage}
                  alt="Token Management"
                />
              </Box>
            </Box>
          </Flex>
        </AnimatedSection>
      </Container>
    </Box>
  );
};

export default Services;

const styles = {
  row_left: {
    pb: ['65px', '75px', null, '92px', '115px', null, '135px', '150px'],
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: ['column', null, null, 'row'],
    px: [0, null, '40px', 0, null, null, null, '40px'],
  },
  row_right: {
    pb: ['65px', '75px', null, '92px', '115px', null, '135px', '150px'],
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: ['column-reverse', null, null, 'row'],
    px: [0, null, '40px', 0, null, null, null, '40px'],
  },
  thumbWrap: {
    width: [
      '100%',
      null,
      '520px',
      'calc(100% - 360px)',
      'calc(100% - 480px)',
      'calc(100% - 520px)',
    ],
    mt: ['40px', '45px', null, 0],
    textAlign: ['center'],
    mx: [null, null, 'auto', 0],
  },
  thumbnail: {
    maxWidth: '615px',
    mx: 'auto',
  },
  content_left: {
    maxWidth: ['100%', null, '520px', '360px', '480px', '520px'],
    pr: [0, null, null, '35px', '50px', '80px', '100px', '50px'],
    textAlign: ['center', null, null, 'left'],
    mx: [null, null, 'auto', 0],
    h2: {
      color: 'black',
      letterSpacing: '-1.2px',
      fontSize: ['24px', '28px', '32px', null, '36px', '40px', '44px', '50px'],
      lineHeight: [1.4, null, 1.35, null, null, 1.25],
      fontWeight: 'bold',
      fontFamily: 'archivo',
    },
    p: {
      fontSize: ['15px', null, '16px'],
      lineHeight: [1.9, null, 2.12],
      color: 'body',
      mt: ['12px', '15px'],
      mb: ['18px', '22px', '25px', '20px', '25px', '30px', '40px'],
    },
    span: {
      color: 'text_secondary',
      fontSize: '14px',
    },
  },
  content_right: {
    maxWidth: ['100%', null, '520px', '360px', '480px', '520px'],
    pl: [0, null, null, '35px', '50px', '80px', '100px', '50px'],
    textAlign: ['center', null, null, 'left'],
    mx: [null, null, 'auto', 0],
    h2: {
      color: 'heading',
      letterSpacing: '-1.2px',
      fontSize: ['24px', '28px', '32px', null, '36px', '40px', '44px', '50px'],
      lineHeight: [1.4, null, 1.35, null, null, 1.25],
      fontWeight: 'bold',
      fontFamily: 'archivo',
    },
    p: {
      fontSize: ['15px', null, '16px'],
      lineHeight: [1.9, null, 2.12],
      color: 'body',
      mt: ['12px', '15px'],
      mb: ['18px', '22px', '25px', '20px', '25px', '30px', '40px'],
    },
    span: {
      color: 'text_secondary',
      fontSize: '14px',
    },
  },
};
