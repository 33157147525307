import React from 'react';
import { Container, Box, Heading, Text } from 'theme-ui';

const MISSION_DATA = {
  title: 'Our Mission:',
  slogan_black: 'We are on a mission to',
  slogan_blue: 'Innovate the World with\n Innovative Technology.',
  text:
    'SPLIT, powered by Funble, revolutionizes access to capital markets with its leading RWA tokenization platform. Offering licensed, regulated, and innovative solutions, SPLIT empowers asset owners, companies and investors in primary raises, recapitalizations, and secondary trading.',
};

const Mission = () => {
  const { title, slogan_black, slogan_blue, text } = MISSION_DATA;
  return (
    <Box as="section" id="mission" sx={styles.section}>
      <Container sx={styles.container}>
        <Box sx={styles.content}>
          <Heading as="h3">{title}</Heading>
          <Heading as="h2">{slogan_black}</Heading>
          <Heading as="h1">{slogan_blue.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}</Heading>
          <Text as="p" sx={styles.text}>{text}</Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Mission;

const styles = {
  section: {
    overflow: 'hidden',
    pt: ['115px', null, null, '140px', '150px', '170px', '185px'],
    pb: ['60px', '75px', null, '90px', '110px', '120px', '140px', '160px'],
    minWidth: '100%',
    backgroundColor: '#f4f4f7',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: ['column', null, null, 'row'],
  },
  content: {
    maxWidth: ['100%', null, null, '500px', '700px', 'null', '900px', '1100px'],
    textAlign: ['center', 'center', null, 'left'],
    h1: {
      fontSize: ['28px', '32px', null, '34px', '40px', '48px', '54px', '80px'],
      lineHeight: [1.4, null, null, 1.35],
      fontFamily: 'archivo',
      letterSpacing: '-1.5px',
      fontWeight: 'bold',
      mx: ['0', null, null, 'auto', '0'],
      color: '#0080FF',
    },
    h2: {
      fontSize: ['28px', '32px', null, '34px', '40px', '48px', '54px', '80px'],
      lineHeight: [1.4, null, null, 1.35],
      fontFamily: 'archivo',
      letterSpacing: '-1.5px',
      fontWeight: 'bold',
      mx: ['0', null, null, 'auto', '0'],
      color: '#000000',
    },
    h3: {
      fontSize: ['20px', '20px', null, '25px', '30px', '35px', '40px', '50px'],
      lineHeight: [1.4, null, null, 1.35],
      fontFamily: 'archivo',
      letterSpacing: '-1.5px',
      fontWeight: 'bold',
      mb: ['0', '20px', '30px', '40px', '50px'],
      color: '#000000',
    },
    p: {
      fontSize: ['15px', null, null, null, '20px', '20px'],
      color: 'text',
      mt: [3, null, null, '30px'],
      pr: [0, null, null, null, null, null, null, '50px'],
    },
  },
  text: {
    maxWidth: '2100px',
  },
};
