/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const PortfolioCard = ({ portfolios }) => {
  return (
    <Box sx={styles.card}>
      <Box sx={styles.portfolios}>
        <GatsbyImage image={portfolios.image} alt={portfolios.title} sx={styles.image} />
        <Text as="h3" sx={styles.title}>{portfolios.title}</Text>
        <Text as="h3" sx={styles.location}>{portfolios.location}</Text>
      </Box>
      {portfolios.features.map((feature, index) => (
        <Box key={index} sx={styles.feature}>
          <Text as="h4">{feature.contentTitle}</Text>
          <Text as="p">{feature.description}</Text>
          {index < portfolios.features.length - 1 && <Box sx={styles.divider} />}
        </Box>
      ))}
    </Box>
  );
};

export default PortfolioCard;

const styles = {
  card: {
    border: `1px solid #edeff6`,
    borderRadius: 5,
    overflow: 'hidden', 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    transition: 'all 0.3s ease-in-out 0s',
    ':hover': {
      boxShadow: '0px 11px 30px rgba(51, 83, 145, 0.07)',
      borderColor: 'transparent',
      transform: 'scale(1.005)',
      filter: 'brightness(1.1)',
    },
  },
  portfolios: {
    position: 'relative', 
    width: '100%',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    // padding: '10px',
    marginBottom: '20px',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '5px 5px 0 0',
    objectFit: 'cover', 
  },
  title: {
    position: 'absolute',
    bottom: '40px', 
    left: '20px',
    fontWeight: 'bold',
    fontSize: ['20px', null, null, '25px'],
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
    marginBottom: '5px',
  },
  location: {
    position: 'absolute',
    bottom: '10px', 
    left: '20px',
    fontSize: ['20px', null, null, '25px'],
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
  },
  feature: {
    textAlign: 'left',
    width: '80%',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    borderBottom: '1px solid #ddd',
    margin: '10px 0',
    width: '100%',
  },
};