/** @jsx jsx */
import { jsx } from 'theme-ui';
import { keyframes } from '@emotion/react';
import { Box, Container, Flex, Heading, Image as Img } from 'theme-ui';
import { alliance_ci } from 'assets/assets.js';

const ALLIANCE = {
  alliance_blockTitle: {
    title: 'Trusted by the Best',
    text: 'FUNBLE is participating in various STO Councils',
  },
  alliance_posts_1: [
    { icon: alliance_ci.sk_securities, title: 'SK Securities' },
    { icon: alliance_ci.woori_bank, title: 'Woori Bank' },
    { icon: alliance_ci.woori_asset_trust, title: 'Woori Asset Trust' },
    { icon: alliance_ci.korea_trust, title: 'Korea Trust' },
    { icon: alliance_ci.nh_investment_securities, title: 'NH Investment Securities' },
    { icon: alliance_ci.nh_bank, title: 'NH Bank' },
    { icon: alliance_ci.hana_bank, title: 'Hana Bank' },
  ],
  alliance_posts_2: [
    { icon: alliance_ci.kiwoom_securities, title: 'Kiwoom Securities' },
    { icon: alliance_ci.daehan_realestate_trust, title: 'Daehan Real Estate Trust' },
    { icon: alliance_ci.ibk_seucirities, title: 'IBK Securities' },
    { icon: alliance_ci.fsc, title: 'Financial Services Commission' },
    { icon: alliance_ci.kibo, title: 'Korea Technology Finance Corporation' },
    { icon: alliance_ci.kodit, title: 'Korea Credit Guarantee Fund' },
    { icon: alliance_ci.seoul_fintech_lab, title: 'Seoul Fintech Lab' },
  ],
};

const aniScroll = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
`;

const aniScrollReverse = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
`;

const aniScroll_r = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
`;

const aniScrollReverse_r = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
`;

const aniSpeed = '50s';

const Alliance = () => {
  const { alliance_blockTitle, alliance_posts_1, alliance_posts_2  } = ALLIANCE;

  return (
    <Box as="section" id="alliance" sx={styles.section}>
      <Container sx={styles.titleContainer}>
        <Box sx={styles.content}>
          <Heading as="h1">{alliance_blockTitle.title}</Heading>
        </Box>
      </Container>
      <Container sx={styles.container}>
        <Flex as="row" sx={styles.row} css={{ animation: `${aniScroll_r} ${aniSpeed} linear infinite` }}>
          {alliance_posts_1.map(({ icon, title }, index) => (
            <Box as="column" key={index} sx={styles.imageWrap}>
              <Img src={icon} alt={`${title} icon`} sx={styles.img} />
            </Box>
          ))}
        </Flex>
        <Flex as="row" sx={styles.row} css={{ animation: `${aniScrollReverse_r} ${aniSpeed} linear infinite` }}>
          {alliance_posts_1.map(({ icon, title }, index) => (
            <Box as="column" key={index} sx={styles.imageWrap}>
              <Img src={icon} alt={`${title} icon`} sx={styles.img} />
            </Box>
          ))}
        </Flex>
      </Container>
      <Container sx={styles.container}>
        <Flex as="row" sx={styles.row} css={{ animation: `${aniScroll} ${aniSpeed} linear infinite` }}>
          {alliance_posts_2.map(({ icon, title }, index) => (
            <Box as="column" key={index} sx={styles.imageWrap}>
              <Img src={icon} alt={`${title} icon`} sx={styles.img} />
            </Box>
          ))}
        </Flex>
        <Flex as="row" sx={styles.row} css={{ animation: `${aniScrollReverse} ${aniSpeed} linear infinite` }}>
          {alliance_posts_2.map(({ icon, title }, index) => (
            <Box as="column" key={index} sx={styles.imageWrap}>
              <Img src={icon} alt={`${title} icon`} sx={styles.img} />
            </Box>
          ))}
        </Flex>
      </Container>     
    </Box>
  );
};

export default Alliance;

const styles = {
  section: {
    pb: ['20px', '30px', null, '50px', '85px', null, '105px', '125px', '140px'],
    mb: '50px',
  },
  titleContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    position: 'relative', 
    display: 'flex',
    alignItems: 'center',
    height: '130px',
    overflow: 'hidden',
  },
  content: {
    maxWidth: ['100%', null, null, '500px', '700px', 'null', '900px', '1100px'],
    textAlign: ['center', null, null, 'left'],
    h1: {
      fontSize: ['28px', '32px', null, '34px', '40px', '48px', '80px', '80px'],
      lineHeight: [1.4, null, null, 1.35],
      color: 'black',
      fontFamily: 'archivo',
      letterSpacing: '-1.5px',
      fontWeight: 'bold',
      mx: ['0', null, null, 'auto', '0'],
      mt: '150px',
      mb: '50px',
    },
  },
  thumbnail: {
    maxWidth: '740px',
    ml: 'auto',
  },
  text: {
    maxWidth: '850px',
  },
  row: {
    position: 'absolute',
    display: 'flex',
    width: 'max-content',
    flexWrap: 'wrap',
  },
  column: {
    listStyle: 'none',
    width: '180px',
    height: '100px',
    lineHeight: '100px',
    textAlign: 'center',
    color: '#fff',
  },
  img: {
    width: '100px',
    height: 'auto',
    filter: 'grayscale(100%)',
  },
  imageWrap: {
    display: 'flex',
    minHeight: ['auto', '83px'],
    alignItems: 'center',
    justifyContent: 'center',
    img: {
      width: ['100px', '130px', '150px', '180px', '200px'],
    },
    mx: '20px',
  },
};