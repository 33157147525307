import React, { useState, useEffect } from 'react';
import { Container, Box, Heading, Text } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const BANNER_DATA = {
  title: 'Step into the Future:',
  sub_title: 'Transform Real World Assets \ninto Digital Opportunities.',
  text: 'Connecting the world\'s best assets with global investors, \nand providing global investors with exciting investment opportunities.',
};

const AnimatedSection = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 80 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

const Banner = () => {
  const { title, sub_title, text } = BANNER_DATA;
  const [isContentVisible, setContentVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setContentVisible(true);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "main_image.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], quality: 90)
        }
      }
    }
  `);

  const image = getImage(data.mainImage.childImageSharp);

  return (
    <Box as="section" id="banner" sx={styles.section}>
      <GatsbyImage
        image={image}
        alt="Banner Background"
        style={styles.imageBackground}
      />
      <Container sx={styles.container}>
        {isContentVisible ? (
          <AnimatedSection>
            <Box sx={styles.content}>
              <Heading as="h1">{title}</Heading>
              <Heading as="h2">
                {sub_title.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Heading>
              <Text as="p" sx={styles.text}>
                {text.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Text>
            </Box>
          </AnimatedSection>
        ) : null}
      </Container>
    </Box>
  );
};

export default Banner;

const styles = {
  section: {
    overflow: 'hidden',
    position: 'relative',
    pb: ['60px', '75px', null, '90px', '110px', '120px', '140px', '160px'],
    minWidth: '100%',
    minHeight: '100vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    height: '100vh',
    textAlign: ['center', null, null, 'left'],
    zIndex: 1,
  },
  imageBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  content: {
    maxWidth: ['100%', null, null, '500px', '700px', 'null', '900px', '1100px'],
    textAlign: ['center', null, null, 'left'],
    zIndex: 1,
    h1: {
      fontSize: ['28px', '32px', null, '34px', '48px', '48px', '70px', '80px'],
      lineHeight: [1.4, null, null, 1.35],
      fontFamily: 'archivo',
      letterSpacing: '-1.5px',
      fontWeight: 'bold',
      mx: ['0', null, null, 'auto', '0'],
      color: '#0080FF',
    },
    h2: {
      fontSize: ['28px', '32px', null, '34px', '48px', '48px', '70px', '80px'],
      lineHeight: [1.4, null, null, 1.35],
      fontFamily: 'archivo',
      letterSpacing: '-1.5px',
      fontWeight: 'bold',
      mx: ['0', null, null, 'auto', '0'],
      color: '#ffffff',
    },
    p: {
      fontSize: ['15px', 'null', null, 'null', 'null', '20px', 'null', '25px'],
      mt: [3, null, null, '18px'],
      pr: [0, null, null, null, null, null, null, '50px'],
      color: '#ffffff',
    },
  },
};