/** @jsx jsx */
import { jsx, Box, Container, Grid } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import SectionHeader from 'components/section-header';
import TeamCard from 'components/cards/team-card';
import { FaLinkedin, FaFacebook } from 'react-icons/fa';


export default function Team() {
  const teamImage = useStaticQuery(graphql`
    query {
      JasonJo: file(relativePath: { eq: "team/member-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], quality: 90)
        }
      }
      KayKim: file(relativePath: { eq: "team/member-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], quality: 90)
        }
      }
      BrianKim: file(relativePath: { eq: "team/member-3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], quality: 90)
        }
      }
      ChrisJeon: file(relativePath: { eq: "team/member-4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], quality: 90)
        }
      }
      DorianKim: file(relativePath: { eq: "team/member-5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], quality: 90)
        }
      }
      AndyKim: file(relativePath: { eq: "team/member-6.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], quality: 90)
        }
      }
      LucyPark: file(relativePath: { eq: "team/member-7.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], quality: 90)
        }
      }
      KaiKim: file(relativePath: { eq: "team/member-8.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], quality: 90)
        }
      }
    }
  `);

  const data = [
    {
      id: 1,
      imgSrc: teamImage.JasonJo.childImageSharp.gatsbyImageData,
      altText: 'Jason Jo',
      title: 'Jason Jo',
      designation: 'Co-founder & CEO',
      socialProfile: [
        {
          id: 1,
          name: 'linkedin',
          path: 'https://www.linkedin.com/in/jason-jo-a8309487/',
          icon: <FaLinkedin />,
        },
        // socialShare 항목의 path가 # 일 경우 화면에 표시하지 않음
        // {
        //   id: 2,
        //   name: 'facebook',
        //   path: '#',
        //   icon: <FaFacebook />,
        // },
      ],
    },
    {
      id: 2,
      imgSrc: teamImage.KayKim.childImageSharp.gatsbyImageData,
      altText: 'Kay Kim',
      title: 'Kay Kim',
      designation: 'Co-founder & CBO',
      socialProfile: [
        {
          id: 1,
          name: 'linkedin',
          path: 'https://www.linkedin.com/in/gieun-kay-kim-b03ba264/',
          icon: <FaLinkedin />,
        },
      ],
    },
    {
      id: 3,
      imgSrc: teamImage.BrianKim.childImageSharp.gatsbyImageData,
      altText: 'Brian Kim',
      title: 'Brian Kim',
      designation: 'CTO',
      socialProfile: [
        {
          id: 1,
          name: 'linkedin',
          path: 'https://www.linkedin.com/in/briankim6758/',
          icon: <FaLinkedin />,
        },
      ],
    },
    {
      id: 4,
      imgSrc: teamImage.ChrisJeon.childImageSharp.gatsbyImageData,
      altText: 'Chris Jeon',
      title: 'Chris Jeon',
      designation: 'CFO',
      socialProfile: [
        {
          id: 1,
          name: 'linkedin',
          path: 'https://www.linkedin.com/in/chris-jeon-700625307/',
          icon: <FaLinkedin />,
        },
      ],
    },
    {
      id: 5,
      imgSrc: teamImage.DorianKim.childImageSharp.gatsbyImageData,
      altText: 'Dorian Kim',
      title: 'Dorian Kim',
      designation: 'CPO & CISO',
      socialProfile: [
        {
          id: 1,
          name: 'linkedin',
          path: 'https://www.linkedin.com/in/dorian1/',
          icon: <FaLinkedin />,
        },
      ],
    },
    {
      id: 6,
      imgSrc: teamImage.AndyKim.childImageSharp.gatsbyImageData,
      altText: 'Livia Kim',
      title: 'Livia Kim',
      designation: 'CCO',
      socialProfile: [
        {
          id: 1,
          name: 'linkedin',
          path: '#',
          icon: <FaLinkedin />,
        },
      ],
    },
    {
      id: 7,
      imgSrc: teamImage.LucyPark.childImageSharp.gatsbyImageData,
      altText: 'Lucy Park',
      title: 'Lucy Park',
      designation: 'Investment Head',
      socialProfile: [
        {
          id: 1,
          name: 'linkedin',
          path: 'https://www.linkedin.com/in/lucypark171/',
          icon: <FaLinkedin />,
        },
      ],
    },
    {
      id: 8,
      imgSrc: teamImage.KaiKim.childImageSharp.gatsbyImageData,
      altText: 'Kai Kim',
      title: 'Kai Kim',
      designation: 'IT Platform Head',
      socialProfile: [
        {
          id: 1,
          name: 'linkedin',
          path: 'https://www.linkedin.com/in/kgeonw/',
          icon: <FaLinkedin />,
        },
      ],
    },
  ];

  return (
    <Box as="section" id="team-section" sx={styles.section}>
      <Container>
        <SectionHeader
          title="Team Leadership"
          slogan={"We bring together luminaries in digital assets and technology, with an experienced team of \nentrepreneurs, technologists, investment specialists, legal and compliance professionals."}
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <TeamCard
              key={`team--key${item.id}`}
              src={item.imgSrc}
              altText={item.altText}
              title={item.title}
              designation={item.designation}
              social={item.socialProfile}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

const styles = {
  section: {
    overflow: 'hidden',
    pt: '8',
    pb: '8',
    backgroundColor: '#f4f4f7',
  },
  grid: {
    mt: [0, null, -6, null, -4],
    gridGap: ['35px 0px', null, 0, null, null, '30px 35px'],
    gridTemplateColumns: [
      'repeat(2,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(4,1fr)',
    ],
  },
};
